// TRANSITION GENERATOR
@mixin transition($property: color, $speed: 0.2s, $mobile: false) {
  @if $mobile {
    transition: $property $speed;
  } @else {
    @media screen and (min-width: $large-mq) {
      transition: $property $speed;
    }
  }
}

// EXAMPLE
// @include transition(color, 0.2s, ease, true);
