// FONTFACE
@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    src: url('#{$file-path}.eot');
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
         url('#{$file-path}.woff') format('woff'),
         url('#{$file-path}.ttf') format('truetype'),
         url('#{$file-path}.svg##{$font-family}') format('svg');
  }
}

// DEFAULTS
@mixin italic() {
  font-style: italic;
}

@mixin light() {
  font-weight: 300;
  font-style: normal;
}

@mixin regular() {
  font-weight: 400;
  font-style: normal;
}

@mixin bold() {
  font-weight: 700;
  font-style: normal;
}

@include font-face('HelveticaNeue-CondensedBold', '../fonts/HelveticaNeue-CondensedBold/HelveticaNeue-CondensedBold');

@mixin font-family($alt: false) {
  @if $alt {
    font-family: 'HelveticaNeue-CondensedBold', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  } @else {
    font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  }
}

// ICONS
@include font-face('icomoon', '../fonts/icomoon/icomoon');

[class*='u-icon--'] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  @include font-smoothing();
}

.u-icon--issuu {
  color: #ed5225;

  &:before {
    content: '\e600';
  }

  &:hover {
    @media only screen and (min-width: $large-mq) {
      color: #ff3e06;
    }
  }
}

.u-icon--mail {
  &:before {
    content: '\e601';
  }
}

.u-icon--facebook {
  color: #4b66a0;

  &:before {
    content: '\e602';
  }

  &:hover {
    @media only screen and (min-width: $large-mq) {
      color: #6793f2;
    }
  }
}

.u-icon--twitter {
  color: #55acee;

  &:before {
    content: '\e603';
  }

  &:hover {
    @media only screen and (min-width: $large-mq) {
      color: #57b7ff;
    }
  }
}

.u-icon--linkedin {
  &:before {
    content: '\e604';

    color: #0077b5;
  }

  &:hover {
    @media only screen and (min-width: $large-mq) {
      color: #00a8ff;
    }
  }
}

.u-icon--right:before {
  content: '\e605';
}

.u-icon--left:before {
  content: '\e606';
}

.u-icon--x {
  display: block;
  @include rem((width: 24px));
  @include rem((height: 24px));
  @include rem((font-size: 18px));
  @include rem((line-height: 24px));

  background-color: $black;

  text-align: center;
  color: $brand;

  @include transition(color, 0.3s);

  &:before {
    content: '\e607';
  }

  &:hover {
    @media only screen and (min-width: $large-mq) {
      color: $white;
    }
  }
}
