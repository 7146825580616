// BACKGROUND IMAGES
%background {
  background-position: center center;
  background-repeat: no-repeat;
}

%background-cover {
  @extend %background;
  background-size: cover;
}

%background-contain {
  @extend %background;
  background-size: contain;
}
