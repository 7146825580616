[class*="u-symbol"] {
  display: inline-block;
  position: relative;
  width: 100%;
  &:before {
    content: '';
    display: block;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@import 'sprite';
