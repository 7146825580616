// (https://css-tricks.com/centering-in-the-unknown/)
%align-center {
  height: 100%;
  min-height: 0;
  font-size: 0;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  > div {
    width: 100%;
    padding-left: $gutter;
    padding-right: $gutter;
    margin: 0 auto;
    display: inline-block;
    vertical-align: middle;
    max-width: 90%;
  }
}
