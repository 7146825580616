// COLORS
$brand: #98a33f;
$background: #002811;
$black: #000;
$white: #fff;


// INITIAL BROWSER SIZES
$browser-context: 16px;
$line-height-base: 26px;


// BEFORE SETTING WIDTHS AND HEIGHTS
@import 'lib/em';
@import 'lib/rem';
@import 'lib/lineheight';


// GRID SETTINGS
$max-width: 960px;
$gutter: 16px;
$float-direction: left;


// STATIC MEDIA QUERIES
$small-mq: em(480px);
$medium-mq: em(768px);
$large-mq: em(960px);
$xlarge-mq: em(1024px);



// PROJECT LIB
@import 'lib/transition';
@import 'lib/acceleration';
@import 'lib/intrinsic';
@import 'lib/bem';
@import 'lib/smoothing';
@import 'lib/opacity';
@import 'lib/clearfix';
@import 'lib/fonts';
@import 'lib/replace';
@import 'lib/images';
@import 'lib/alignment';
@import 'lib/retina';
@import 'lib/appearance';
@import 'lib/visually-hidden';
@import 'lib/firefox';
@import 'lib/chevron';
@import 'lib/interaction';
@import 'lib/placeholder';
@import 'lib/selection';


// SPRITE CONFIGURATION
@import 'lib/sprite-config';
@import 'lib/sprite';


// HELPER CLASSES
@import 'lib/helpers';
