.row {
  width: 100%;
  margin: 0 auto;
  @include rem((max-width: $max-width));
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
  .row {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    @include rem((margin-left: -$gutter));
    @include rem((margin-right: -$gutter));
    margin-top: 0;
    margin-bottom: 0;
    max-width: none;
    &.collapse {
      width: auto;
      margin: 0;
      max-width: none;
    }
  }
  &.collapse {
    > .column {
      position: relative;
      padding-left: 0;
      padding-right: 0;
      float: left;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.column {
  width: 100%;

  @media screen and (min-width: $small-mq) {
    float: $float-direction;
  }

  @include rem((padding-left: $gutter));
  @include rem((padding-right: $gutter));
}

.column-alt-flow {
  @media screen and (min-width: $small-mq) {
    float: right !important;
  }
}

[class*="-push-"],
[class*="-pull-"] {
  @media screen and (min-width: $small-mq) {
    position: relative;
  }
}

@media screen and (min-width: $small-mq) {
  .small-push-1 {
    left: 8.33333%;
    right: auto;
  }

  .small-pull-1 {
    right: 8.33333%;
    left: auto;
  }

  .small-push-2 {
    left: 16.66667%;
    right: auto;
  }

  .small-pull-2 {
    right: 16.66667%;
    left: auto;
  }

  .small-push-3 {
    left: 25%;
    right: auto;
  }

  .small-pull-3 {
    right: 25%;
    left: auto;
  }

  .small-push-4 {
    left: 33.33333%;
    right: auto;
  }

  .small-pull-4 {
    right: 33.33333%;
    left: auto;
  }

  .small-push-5 {
    left: 41.66667%;
    right: auto;
  }

  .small-pull-5 {
    right: 41.66667%;
    left: auto;
  }

  .small-push-6 {
    left: 50%;
    right: auto;
  }

  .small-pull-6 {
    right: 50%;
    left: auto;
  }

  .small-push-7 {
    left: 58.33333%;
    right: auto;
  }

  .small-pull-7 {
    right: 58.33333%;
    left: auto;
  }

  .small-push-8 {
    left: 66.66667%;
    right: auto;
  }

  .small-pull-8 {
    right: 66.66667%;
    left: auto;
  }

  .small-push-9 {
    left: 75%;
    right: auto;
  }

  .small-pull-9 {
    right: 75%;
    left: auto;
  }

  .small-push-10 {
    left: 83.33333%;
    right: auto;
  }

  .small-pull-10 {
    right: 83.33333%;
    left: auto;
  }

  .small-push-11 {
    left: 91.66667%;
    right: auto;
  }

  .small-pull-11 {
    right: 91.66667%;
    left: auto;
  }

  .small-1 {
    width: 8.33333%;
  }

  .small-2 {
    width: 16.66667%;
  }

  .small-3 {
    width: 25%;
  }

  .small-4 {
    width: 33.33333%;
  }

  .small-5 {
    width: 41.66667%;
  }

  .small-6 {
    width: 50%;
  }

  .small-7 {
    width: 58.33333%;
  }

  .small-8 {
    width: 66.66667%;
  }

  .small-9 {
    width: 75%;
  }

  .small-10 {
    width: 83.33333%;
  }

  .small-11 {
    width: 91.66667%;
  }

  .small-12 {
    width: 100%;
  }

  .small-centered,
  .column.small-centered {
    margin-left: auto;
    margin-right: auto;
    float: none !important;
  }
}

@media screen and (min-width: $medium-mq) {
  .medium-push-1 {
    left: 8.33333%;
    right: auto;
  }

  .medium-pull-1 {
    right: 8.33333%;
    left: auto;
  }

  .medium-push-2 {
    left: 16.66667%;
    right: auto;
  }

  .medium-pull-2 {
    right: 16.66667%;
    left: auto;
  }

  .medium-push-3 {
    left: 25%;
    right: auto;
  }

  .medium-pull-3 {
    right: 25%;
    left: auto;
  }

  .medium-push-4 {
    left: 33.33333%;
    right: auto;
  }

  .medium-pull-4 {
    right: 33.33333%;
    left: auto;
  }

  .medium-push-5 {
    left: 41.66667%;
    right: auto;
  }

  .medium-pull-5 {
    right: 41.66667%;
    left: auto;
  }

  .medium-push-6 {
    left: 50%;
    right: auto;
  }

  .medium-pull-6 {
    right: 50%;
    left: auto;
  }

  .medium-push-7 {
    left: 58.33333%;
    right: auto;
  }

  .medium-pull-7 {
    right: 58.33333%;
    left: auto;
  }

  .medium-push-8 {
    left: 66.66667%;
    right: auto;
  }

  .medium-pull-8 {
    right: 66.66667%;
    left: auto;
  }

  .medium-push-9 {
    left: 75%;
    right: auto;
  }

  .medium-pull-9 {
    right: 75%;
    left: auto;
  }

  .medium-push-10 {
    left: 83.33333%;
    right: auto;
  }

  .medium-pull-10 {
    right: 83.33333%;
    left: auto;
  }

  .medium-push-11 {
    left: 91.66667%;
    right: auto;
  }

  .medium-pull-11 {
    right: 91.66667%;
    left: auto;
  }

  .medium-1 {
    width: 8.33333%;
  }

  .medium-2 {
    width: 16.66667%;
  }

  .medium-3 {
    width: 25%;
  }

  .medium-4 {
    width: 33.33333%;
  }

  .medium-5 {
    width: 41.66667%;
  }

  .medium-6 {
    width: 50%;
  }

  .medium-7 {
    width: 58.33333%;
  }

  .medium-8 {
    width: 66.66667%;
  }

  .medium-9 {
    width: 75%;
  }

  .medium-10 {
    width: 83.33333%;
  }

  .medium-11 {
    width: 91.66667%;
  }

  .medium-12 {
    width: 100%;
  }

  .medium-centered,
  .column.medium-centered {
    margin-left: auto;
    margin-right: auto;
    float: none !important;
  }
}

@media screen and (min-width: $large-mq) {
  .large-push-1 {
    left: 8.33333%;
    right: auto;
  }

  .large-pull-1 {
    right: 8.33333%;
    left: auto;
  }

  .large-push-2 {
    left: 16.66667%;
    right: auto;
  }

  .large-pull-2 {
    right: 16.66667%;
    left: auto;
  }

  .large-push-3 {
    left: 25%;
    right: auto;
  }

  .large-pull-3 {
    right: 25%;
    left: auto;
  }

  .large-push-4 {
    left: 33.33333%;
    right: auto;
  }

  .large-pull-4 {
    right: 33.33333%;
    left: auto;
  }

  .large-push-5 {
    left: 41.66667%;
    right: auto;
  }

  .large-pull-5 {
    right: 41.66667%;
    left: auto;
  }

  .large-push-6 {
    left: 50%;
    right: auto;
  }

  .large-pull-6 {
    right: 50%;
    left: auto;
  }

  .large-push-7 {
    left: 58.33333%;
    right: auto;
  }

  .large-pull-7 {
    right: 58.33333%;
    left: auto;
  }

  .large-push-8 {
    left: 66.66667%;
    right: auto;
  }

  .large-pull-8 {
    right: 66.66667%;
    left: auto;
  }

  .large-push-9 {
    left: 75%;
    right: auto;
  }

  .large-pull-9 {
    right: 75%;
    left: auto;
  }

  .large-push-10 {
    left: 83.33333%;
    right: auto;
  }

  .large-pull-10 {
    right: 83.33333%;
    left: auto;
  }

  .large-push-11 {
    left: 91.66667%;
    right: auto;
  }

  .large-pull-11 {
    right: 91.66667%;
    left: auto;
  }

  .large-1 {
    width: 8.33333%;
  }

  .large-2 {
    width: 16.66667%;
  }

  .large-3 {
    width: 25%;
  }

  .large-4 {
    width: 33.33333%;
  }

  .large-5 {
    width: 41.66667%;
  }

  .large-6 {
    width: 50%;
  }

  .large-7 {
    width: 58.33333%;
  }

  .large-8 {
    width: 66.66667%;
  }

  .large-9 {
    width: 75%;
  }

  .large-10 {
    width: 83.33333%;
  }

  .large-11 {
    width: 91.66667%;
  }

  .large-12 {
    width: 100%;
  }

  .large-centered,
  .column.large-centered {
    margin-left: auto;
    margin-right: auto;
    float: none !important;
  }
}

@media screen and (min-width: $xlarge-mq) {
  .xlarge-push-1 {
    left: 8.33333%;
    right: auto;
  }

  .xlarge-pull-1 {
    right: 8.33333%;
    left: auto;
  }

  .xlarge-push-2 {
    left: 16.66667%;
    right: auto;
  }

  .xlarge-pull-2 {
    right: 16.66667%;
    left: auto;
  }

  .xlarge-push-3 {
    left: 25%;
    right: auto;
  }

  .xlarge-pull-3 {
    right: 25%;
    left: auto;
  }

  .xlarge-push-4 {
    left: 33.33333%;
    right: auto;
  }

  .xlarge-pull-4 {
    right: 33.33333%;
    left: auto;
  }

  .xlarge-push-5 {
    left: 41.66667%;
    right: auto;
  }

  .xlarge-pull-5 {
    right: 41.66667%;
    left: auto;
  }

  .xlarge-push-6 {
    left: 50%;
    right: auto;
  }

  .xlarge-pull-6 {
    right: 50%;
    left: auto;
  }

  .xlarge-push-7 {
    left: 58.33333%;
    right: auto;
  }

  .xlarge-pull-7 {
    right: 58.33333%;
    left: auto;
  }

  .xlarge-push-8 {
    left: 66.66667%;
    right: auto;
  }

  .xlarge-pull-8 {
    right: 66.66667%;
    left: auto;
  }

  .xlarge-push-9 {
    left: 75%;
    right: auto;
  }

  .xlarge-pull-9 {
    right: 75%;
    left: auto;
  }

  .xlarge-push-10 {
    left: 83.33333%;
    right: auto;
  }

  .xlarge-pull-10 {
    right: 83.33333%;
    left: auto;
  }

  .xlarge-push-11 {
    left: 91.66667%;
    right: auto;
  }

  .xlarge-pull-11 {
    right: 91.66667%;
    left: auto;
  }

  .xlarge-1 {
    width: 8.33333%;
  }

  .xlarge-2 {
    width: 16.66667%;
  }

  .xlarge-3 {
    width: 25%;
  }

  .xlarge-4 {
    width: 33.33333%;
  }

  .xlarge-5 {
    width: 41.66667%;
  }

  .xlarge-6 {
    width: 50%;
  }

  .xlarge-7 {
    width: 58.33333%;
  }

  .xlarge-8 {
    width: 66.66667%;
  }

  .xlarge-9 {
    width: 75%;
  }

  .xlarge-10 {
    width: 83.33333%;
  }

  .xlarge-11 {
    width: 91.66667%;
  }

  .xlarge-12 {
    width: 100%;
  }

  .xlarge-centered,
  .column.xlarge-centered {
    margin-left: auto;
    margin-right: auto;
    float: none !important;
  }
}


/* block grid */

[class*="block-grid-"] {
  display: block;
  padding: 0;
  margin: 0;
  clear: both;
  .column & {
    @include rem((margin-left: -$gutter));
    @include rem((margin-right: -$gutter));
  }
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
  > li {
    display: inline;
    height: auto;
    float: left;
    @include rem((padding-left: $gutter));
    @include rem((padding-right: $gutter));
    position: relative;
    width: 100%;
  }
}


@media screen and (min-width: $small-mq) {
  .small-block-grid-1 > li {
    width: 100%;
  }

  .small-block-grid-1 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }

  .small-block-grid-2 > li {
    width: 50%;
  }

  .small-block-grid-2 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }

  .small-block-grid-3 > li {
    width: 33.33333%;
  }

  .small-block-grid-3 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }

  .small-block-grid-4 > li {
    width: 25%;
  }

  .small-block-grid-4 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }

  .small-block-grid-5 > li {
    width: 20%;
  }

  .small-block-grid-5 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }

  .small-block-grid-6 > li {
    width: 16.66667%;
  }

  .small-block-grid-6 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }

  .small-block-grid-7 > li {
    width: 14.28571%;
  }

  .small-block-grid-7 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }

  .small-block-grid-8 > li {
    width: 12.5%;
  }

  .small-block-grid-8 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }

  .small-block-grid-9 > li {
    width: 11.11111%;
  }

  .small-block-grid-9 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }

  .small-block-grid-10 > li {
    width: 10%;
  }

  .small-block-grid-10 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }

  .small-block-grid-11 > li {
    width: 9.09091%;
  }

  .small-block-grid-11 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }

  .small-block-grid-12 > li {
    width: 8.33333%;
  }

  .small-block-grid-12 > li:nth-of-type(n) {
    clear: none;
  }

  .small-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }
}
@media screen and (min-width: $medium-mq) {
  .medium-block-grid-1 > li {
    width: 100%;
  }

  .medium-block-grid-1 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }

  .medium-block-grid-2 > li {
    width: 50%;
  }

  .medium-block-grid-2 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }

  .medium-block-grid-3 > li {
    width: 33.33333%;
  }

  .medium-block-grid-3 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }

  .medium-block-grid-4 > li {
    width: 25%;
  }

  .medium-block-grid-4 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }

  .medium-block-grid-5 > li {
    width: 20%;
  }

  .medium-block-grid-5 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }

  .medium-block-grid-6 > li {
    width: 16.66667%;
  }

  .medium-block-grid-6 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }

  .medium-block-grid-7 > li {
    width: 14.28571%;
  }

  .medium-block-grid-7 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }

  .medium-block-grid-8 > li {
    width: 12.5%;
  }

  .medium-block-grid-8 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }

  .medium-block-grid-9 > li {
    width: 11.11111%;
  }

  .medium-block-grid-9 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }

  .medium-block-grid-10 > li {
    width: 10%;
  }

  .medium-block-grid-10 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }

  .medium-block-grid-11 > li {
    width: 9.09091%;
  }

  .medium-block-grid-11 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }

  .medium-block-grid-12 > li {
    width: 8.33333%;
  }

  .medium-block-grid-12 > li:nth-of-type(n) {
    clear: none;
  }

  .medium-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }
}

@media screen and (min-width: $large-mq) {
  .large-block-grid-1 > li {
    width: 100%;
  }

  .large-block-grid-1 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }

  .large-block-grid-2 > li {
    width: 50%;
  }

  .large-block-grid-2 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }

  .large-block-grid-3 > li {
    width: 33.33333%;
  }

  .large-block-grid-3 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }

  .large-block-grid-4 > li {
    width: 25%;
  }

  .large-block-grid-4 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }

  .large-block-grid-5 > li {
    width: 20%;
  }

  .large-block-grid-5 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }

  .large-block-grid-6 > li {
    width: 16.66667%;
  }

  .large-block-grid-6 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }

  .large-block-grid-7 > li {
    width: 14.28571%;
  }

  .large-block-grid-7 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }

  .large-block-grid-8 > li {
    width: 12.5%;
  }

  .large-block-grid-8 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }

  .large-block-grid-9 > li {
    width: 11.11111%;
  }

  .large-block-grid-9 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }

  .large-block-grid-10 > li {
    width: 10%;
  }

  .large-block-grid-10 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }

  .large-block-grid-11 > li {
    width: 9.09091%;
  }

  .large-block-grid-11 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }

  .large-block-grid-12 > li {
    width: 8.33333%;
  }

  .large-block-grid-12 > li:nth-of-type(n) {
    clear: none;
  }

  .large-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }
}

@media screen and (min-width: $xlarge-mq) {
  .xlarge-block-grid-1 > li {
    width: 100%;
  }

  .xlarge-block-grid-1 > li:nth-of-type(n) {
    clear: none;
  }

  .xlarge-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }

  .xlarge-block-grid-2 > li {
    width: 50%;
  }

  .xlarge-block-grid-2 > li:nth-of-type(n) {
    clear: none;
  }

  .xlarge-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }

  .xlarge-block-grid-3 > li {
    width: 33.33333%;
  }

  .xlarge-block-grid-3 > li:nth-of-type(n) {
    clear: none;
  }

  .xlarge-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }

  .xlarge-block-grid-4 > li {
    width: 25%;
  }

  .xlarge-block-grid-4 > li:nth-of-type(n) {
    clear: none;
  }

  .xlarge-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }

  .xlarge-block-grid-5 > li {
    width: 20%;
  }

  .xlarge-block-grid-5 > li:nth-of-type(n) {
    clear: none;
  }

  .xlarge-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }

  .xlarge-block-grid-6 > li {
    width: 16.66667%;
  }

  .xlarge-block-grid-6 > li:nth-of-type(n) {
    clear: none;
  }

  .xlarge-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }

  .xlarge-block-grid-7 > li {
    width: 14.28571%;
  }

  .xlarge-block-grid-7 > li:nth-of-type(n) {
    clear: none;
  }

  .xlarge-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }

  .xlarge-block-grid-8 > li {
    width: 12.5%;
  }

  .xlarge-block-grid-8 > li:nth-of-type(n) {
    clear: none;
  }

  .xlarge-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }

  .xlarge-block-grid-9 > li {
    width: 11.11111%;
  }

  .xlarge-block-grid-9 > li:nth-of-type(n) {
    clear: none;
  }

  .xlarge-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }

  .xlarge-block-grid-10 > li {
    width: 10%;
  }

  .xlarge-block-grid-10 > li:nth-of-type(n) {
    clear: none;
  }

  .xlarge-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }

  .xlarge-block-grid-11 > li {
    width: 9.09091%;
  }

  .xlarge-block-grid-11 > li:nth-of-type(n) {
    clear: none;
  }

  .xlarge-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }

  .xlarge-block-grid-12 > li {
    width: 8.33333%;
  }

  .xlarge-block-grid-12 > li:nth-of-type(n) {
    clear: none;
  }

  .xlarge-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }
}


.block-reset {
  .column & {
    margin-left: 0;
    margin-right: 0;
  }
  > li {
    padding-left: 0;
    padding-right: 0;
    .column & {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
