// HELPERS
%hairline {
  border-style: solid;
  border-color: #d5d6d7;
}

[class*="hairline"] {
  @extend %hairline;
}

.hairline-top {
  border-width: 1px 0 0 0;
}

.hairline-bottom {
  border-width: 0 0 1px 0;
}

.hairline-left {
  border-width: 0 0 0 1px;
}

.hairline-right {
  border-width: 0 1px 0 0;
}

.hairline {
  border-width: 1px;
}

.highlight {
  background-color: #eee;
}

.highlight-alt {
  background-color: #fcfcfc;
}

.arrow-up {
  &:before {
    @include chevron('up');
  }
}

.arrow-right {
  &:before {
    @include chevron('right');
  }
}

.arrow-down {
  &:before {
    @include chevron('down');
  }
}

.arrow-left {
  &:before {
    @include chevron('left');
  }
}

%opacity-0 {
  @include opacity(0);
}

%opacity-1 {
  @include opacity(1);
}

%opacity-2 {
  @include opacity(0.2);
}

%opacity-3 {
  @include opacity(0.3);
}

%opacity-4 {
  @include opacity(0.4);
}

%opacity-5 {
  @include opacity(0.5);
}

%opacity-6 {
  @include opacity(0.6);
}

%opacity-7 {
  @include opacity(0.7);
}

%opacity-8 {
  @include opacity(0.8);
}

%opacity-9 {
  @include opacity(0.9);
}

.alt-text {
  @extend %replace;
}

.clearfix {
  @include clearfix();
}
