// THEME
@include selection {
  background: $brand;
  text-shadow: none;
  color: $white;
}


html,
body {
  @include font-smoothing();
}


html {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  background-color: $background;

  color: $white;
  font-size: percentage($browser-context / $browser-context);
  @include font-family();

  @media only screen and (min-width: $small-mq) {
    font-size: percentage(($browser-context + 1px) / $browser-context);
  }

  @media only screen and (min-width: $medium-mq) {
    font-size: percentage(($browser-context + 2px) / $browser-context);
  }

  .touch & {
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: $white;
  }
}


body {
  min-height: 100%;
  @include rem((font-size: 13px));
  @include rem((line-height: 18px));
  @include rem((padding-top: 51px));

  @media only screen and (min-width: $xlarge-mq) {
    padding-top: 0;
  }

}


// HEADER
.mastheader {
  @include rem((padding: $gutter*2 $gutter));

  @media only screen and (min-width: $xlarge-mq) {
    @include rem((padding: $gutter*2 0));

    text-align: right;
  }

  .row > .column {
    @media only screen and (min-width: $xlarge-mq) {
      position: relative;
    }
  }
}


// BANNER
.banner {
  .row > .column {
    @media only screen and (min-width: $xlarge-mq) {
      position: relative;
      @include rem((padding-left: 160px + $gutter));
    }
  }
}

.banner-image {
  width: 100%;
  height: 0;
  padding-bottom: intrinsic(310, 815);
  @extend %background-cover;
}


// NAVIGATION
.nav {
  display: none;

  @media only screen and (min-width: $xlarge-mq) {
    display: block;
    width: 100%;
    @include rem((padding: $gutter/2 $gutter $gutter 0));
    position: absolute;
    top: 0;
    @include rem((left: $gutter));
    height: 100%;
    @include rem((max-width: 160px));

    background-color: $brand;

    font-size: 0;
    text-align: right;
  }

  a {
    @media only screen and (min-width: $xlarge-mq) {
      display: block;
      @include rem((padding-top: $gutter/2));

      color: $white;
      @include rem((font-size: 16px));
      @include rem((line-height: 28px));
      @include font-family(true);
    }

    @include transition(color, 0.3s);

    &:hover {
      @media only screen and (min-width: $large-mq) {
        color: $background;
      }
    }

    &.current-post-ancestor,
    &.current-menu-parent,
    &.current-post-parent,
    &.current-menu-item {
      color: $background;
    }

  }
}

.u-cloned--navigation {
  position: fixed;
  top: -300%;

  .nav-is-open & {
    top: 0;
  }

  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 2;

  background-color: rgba(152,163,63,0.8);

  font-size: 0;
  text-align: center;

  @include transition(top, 0.5s, true);

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  nav {
    display: inline-block;
    width: 100%;
    @include rem((max-width: 300px));
    @include rem((padding: 50px $gutter $gutter $gutter));
    vertical-align: middle;
  }

  a {
    display: block;
    @include rem((padding-top: $gutter));

    @include rem((font-size: 16px));
    @include rem((line-height: 24px));
    @include font-family(true);
    color: $white;
  }
}

.u-navigation-toggle {
  @media only screen and (min-width: $xlarge-mq) {
    display: none;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  @include rem((padding: $gutter/3));
  z-index: 3;

  background-color: rgba(152,163,63,0.8);

  a {
    position: relative;
    display: block;
    @include rem((width: 40px));
    @include rem((height: 40px));
    margin: 0 auto;

    background-color: $black;

    border-color: $white;
    @include rem((border-width: 2px));
    border-style: solid;

    &:after,
    &:before {
      content: '';
      @include rem((width: 26px));
      @include rem((height: 2px));
      position: absolute;
      left: 50%;
      @include rem((margin-left: -13px));

      background-color: $white;
    }

    &:before {
      @include rem((top: 11px));
    }

    &:after {
      @include rem((bottom: 11px));
    }
  }

  .nav-is-open & {
    border-color: $background;
    a {
      &:before {
        @include rem((top: 18px));

        transform: rotate(-50deg);
      }

      &:after {
        bottom: auto;
        @include rem((top: 18px));

        transform: rotate(50deg);
      }
    }
  }
}


// ICONS
.u-symbol--brand {
  display: block;
  margin: 0 auto;
  @include rem((max-width: 322px));

  @media only screen and (min-width: $xlarge-mq) {
    position: absolute;
    top: 50%;
    @include rem((left: $gutter));
    @include rem((margin-top: -38px));
  }
}


// Titles
.masthead-title {
  @include rem((padding-top: $gutter));

  @include rem((font-size: 30px));
  @include rem((line-height: 32px));

  text-align: center;

  @media only screen and (min-width:  $xlarge-mq) {
    padding-top: 0;
    display: inline-block;
    vertical-align: middle;

    text-align: left;
  }

  > span {
    display: block;
    @include rem((padding-top: $gutter/2));

    @include rem((font-size: 20px));
    @include rem((line-height: 28px));
    @include font-family();
    @include light();

    @media only screen and (min-width:  $xlarge-mq) {
      padding-top: 0;
    }
  }
}

.u-title {
  text-transform: lowercase;
  @include bold();
  color: $brand;
}

.content .u-title--alpha,
.u-title--alpha {
  @include rem((padding-top: $gutter*2));
  @include rem((padding-bottom: $gutter/2));

  @include rem((font-size: 32px));
  @include rem((line-height: 32px));
  @include rem((letter-spacing: -1px));
}

.content .u-title--beta,
.u-title--beta {
  @include rem((padding-top: ($gutter/2)*3));
  @include rem((padding-bottom: $gutter/2));

  @include rem((font-size: 24px));
  @include rem((line-height: 28px));
  @include bold();
}


// CONTENT
.content {
  dl,
  ul,
  ol,
  p {
    @include rem((padding-top: $gutter/2));
  }

  h1,h2,h3,h4,h5,h6 {
    @include rem((padding-top: $gutter));

    @include rem((font-size: 20px));
    @include rem((line-height: 28px));
    @include bold();
  }

  ul {
    list-style-type: disc;

    @include rem((padding-left: 16px));
  }

  ol {
    list-style-type: decimal;

    @include rem((padding-left: 20px));
  }

  p a,
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a,
  li a,
  dl a {
    color: rgba(255,255,255,0.8);
    @include transition(color, 0.3s);

    &:hover {
      @media only screen and (min-width: $large-mq) {
        color: $brand;
      }
    }
  }

  dt,dd {
    display: inline-block;
  }

  .wpcf7-response-output {
    @include rem((margin: $gutter*2 0 $gutter));
    @include rem((padding: $gutter/2));

    border-color: $brand;

    text-align: center;
    color: $brand;
  }

  .wpcf7-validation-errors {
    border-color: $brand;
  }

}

.content-image {
  @include rem((margin-top: $gutter));
}

.content-link {
  display: block;
  @include rem((padding-top: $gutter/2));

  color: rgba(255,255,255,0.8);

  @include transition(color, 0.3s);

  &:hover {
    @media only screen and (min-width: $large-mq) {
      color: $brand;
    }
  }
}

.content--list {
  @media only screen and (min-width: $large-mq) {
    @include rem((padding-left: $gutter*2));
  }

  ol,
  ul {
    padding-left: 0;

    list-style-type: none;
  }
}


// ADDRESS
.address > span {
  display: block;
}


// TESTIMONIAL
.testimonial .testimonial-post:last-child {
  padding-bottom: 0;
}

.testimonial-post {
  @include rem((padding-bottom: $gutter));
}

.testimonial-content {
  display: block;
  @include rem((padding-top: $gutter/2));

  @include italic();
}

.testimonial-author {
  display: block;
  @include rem((padding-top: $gutter/2));

  color: rgba(255,255,255,0.7);
}

.testimonial-role {
  display: block;

  @include rem((font-size: 10px));
  color: rgba(255,255,255,0.7);
}


// FORMS
.form .filed:last-child {
  margin-bottom: 0;
}

.field {
  @include rem((margin-bottom: $gutter/2));

  textarea {
    @include rem((min-height: 200px));
  }

  span {
    @include rem((font-size: 13px));
    @include rem((line-height: 18px));
    @include rem((padding: $gutter/2 0));

    color: $brand;
  }
}

.field--text {

  input,textarea {
    @include rem((padding: $gutter/2));

    background-color: $white;

    border-width: 1px;
    border-color: $black;
    border-style: solid;
  }
}

.filed--submit {
  text-align: right;
}


// BUTTONS
.u-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
  @include rem((padding: $gutter/2 $gutter));

  background-color: $brand;

  text-align: center;
  text-transform: uppercase;
  color: $white;

  @include transition(color, 0.3s);
  @include transition(background, 0.3s);

  &:after {
    content: '';
    position: absolute;
    @include rem((bottom: -4px));
    left: 0;
    width: 100%;
    @include rem((height: 4px));

    background-color: $brand;

    @include transition(top, 0.5s);
  }

  &:hover {
    @media only screen and (min-width: $large-mq) {
      background-color: $white;

      color: $brand;
    }

    &:after {
      @media only screen and (min-width: $large-mq) {
        bottom: 0;
      }
    }
  }
}

.u-button--icon {
  @include rem((font-size: 30px));
}

.u-button--upload {
  @include rem((margin: $gutter*2 0 $gutter));
}


// FOOTER
.footer {
  @include rem((padding: $gutter*4 0 $gutter/2 ));

  font-size: 0;

  .row > .column {
    position: relative;

    &:last-child {
      @media only screen and (min-width: $xlarge-mq) {
        text-align: right;
      }
    }
  }
}

.footer-companyinfo {
  text-align: center;

  @media only screen and (min-width: $xlarge-mq) {
    display: inline-block;

    white-space: nowrap;
    text-align: left;
  }

  span {
    display: inline-block;
    position: relative;
    vertical-align: middle;

    @include rem((font-size: 11px));
    @include rem((line-height: 14px));

    &:after {
      content: "|";
      display: inline-block;
      @include rem((padding: 0 $gutter/3));
    }

    &:last-child:after {
      display: none;
    }
  }

  a {
    color: rgba(255,255,255,0.8);
    @include transition(color, 0.3s);

    &:hover {
      @media only screen and (min-width: $large-mq) {
        color: $brand;
      }
    }

    &.current-post-ancestor,
    &.current-menu-parent,
    &.current-post-parent,
    &.current-menu-item {
      color: $brand;
    }

  }
}

.footer-companyinfo--alpha {
  a {
    display: inline-block;
    position: relative;
    vertical-align: middle;

    @include rem((font-size: 11px));
    @include rem((line-height: 14px));

    &:after {
      content: "|";
      display: inline-block;
      @include rem((padding: 0 $gutter/3));

      color: $white;
    }

    &:last-child:after {
      display: none;
    }

    &:hover:after {
      @media only screen and (min-width: $large-mq) {
        color: $white;
      }
    }
  }
}

.footer-social {
  display: block;
  clear: both;

  text-align: center;
  font-size: 0;

  @media only screen and (min-width: $xlarge-mq) {
    text-align: right;
  }

  a {
    display: inline-block;
    @include rem((font-size: 32px));
    @include rem((padding-right: $gutter/3));

    @include transition(color, 0.3s);

    &:last-child {
      padding-right: 0;
    }
  }
}


// CAROUSEL
.carousel--portfolio {
  width: 100%;
  position: relative;
  overflow: hidden;
  @include rem((padding: $gutter));

  @media screen and (min-width: $large-mq) {
    padding-bottom: 0;
  }

  @media screen and (min-width: $xlarge-mq) {
    @include rem((padding-bottom: $gutter));
  }

  @media only screen and (min-width: $xlarge-mq) {
    height: 0;
    padding-bottom: intrinsic(320, 815);
  }

  background-color: $black;

  .u-title {
    @include rem((padding: $gutter/2 0 $gutter/2 $gutter));

    text-transform: none;
  }
}

.carousel-pagination {
  @media screen and (min-width: $large-mq) {
    @include rem((padding: $gutter 0 $gutter*2));
  }

  @media screen and (min-width: $xlarge-mq) {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-top: 0;
  }

  width: 100%;

  @include rem((padding: $gutter 0));

  background-color: $black;

  @include clearfix();

  a {
    float: right;

    @include rem((margin: 0 $gutter));

    @media screen and (min-width: $xlarge-mq) {
      @include rem((margin: 0 $gutter*2));
    }

    color: $white;
    @include transition(color, 0.3s);

    &:last-child {
      float: left;
    }

    &:hover {
      @media screen and (min-width: $large-mq) {
        color: $brand;
      }
    }

    &.slick-disabled {
      display: none !important;
    }

  }
}

.portfolio--list {
  @include rem((padding-bottom: $gutter));
}

// // SLIDER
// .slick-slider {
//   position: relative;
//   display: block;
//   box-sizing: border-box;

//   user-select: none;
//   touch-action: pan-y;
//   -webkit-tap-highlight-color: transparent;
// }

// .slick-list {
//   position: relative;
//   overflow: hidden;
//   display: block;
//   margin: 0;
//   padding: 0;

//   &:focus {
//     outline: none;
//   }

//   &.dragging {
//     cursor: pointer;
//     cursor: hand;
//   }
// }

// .slick-slider .slick-track,
// .slick-slider .slick-list {
//   transform: translate3d(0, 0, 0);
// }

// .slick-track {
//   display: block;
//   position: relative;
//   left: 0;
//   top: 0;

//   &:before,
//   &:after {
//     content: "";
//     display: table;
//   }

//   &:after {
//     clear: both;
//   }

//   .slick-loading & {
//     visibility: hidden;
//   }
// }

// .slick-slide {
//   float: left;
//   height: 100%;
//   min-height: 1px;

//   [dir="rtl"] & {
//     float: right;
//   }

//   img {
//     display: block;
//   }

//   &.slick-loading img {
//     display: none;
//   }

//   display: none;

//   &.dragging img {
//     pointer-events: none;
//   }

//   .slick-initialized & {
//     display: block;
//   }

//   .slick-loading & {
//     visibility: hidden;
//   }

//   .slick-vertical & {
//     display: block;
//     height: auto;
//     border: 1px solid transparent;
//   }
// }

// .slick-arrow.slick-hidden {
//   display: none;
// }


// PORTFOLIO
.portfolio {
  display: block;
  position: relative;
  @include rem((padding: $gutter));
  width: 100%;

  color: $white;

  @include transition(color, 0.3s);

  &:hover {
    @media only screen and (min-width: $large-mq) {
      color: $brand;
    }
  }
}

.content-image,
.portfolio-image {
  display: block;
  width: 100%;
  height: 0;

  padding-bottom: intrinsic(115, 179);

  @extend %background-cover;
}

.portfolio-copy {
  display: block;
  width: 100%;
  @include rem((padding: $gutter/2 0 0 0));

  span {
    display: block;

    &:first-child {
      @include font-family(true);
    }
  }
}


// MODAL
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 5;

  background-color: rgba(0,0,0,0.8);
}


.modal-wrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  @include rem((padding: $gutter));

  font-size: 0;
  text-align: center;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

}

.modal-content {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 100%;
  @include rem((max-width: 600px));
  @include rem((padding: $gutter));

  background-color: #253139;

  .u-icon--x {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}

.embed {
  width: 100%;
  height: 100%;
  height: 0;
  padding-bottom: 80%;
  position: relative;

  @include rem((font-size: 13px));
  @include rem((line-height: 18px));
  color: $white;

  a {
    color: $white;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}


// LAYOUT
.l-main {
  > .row {
    @media only screen and (min-width: $xlarge-mq) {
      @include rem((padding-left: 160px));
    }
  }
}

.l-small {
  display: block;

  @media only screen and (min-width: $large-mq) {
    display: none;
  }
}

.l-large {
  display: none;

  @media only screen and (min-width: $large-mq) {
    display: block;

    .content-link {
      @include rem((padding-top: $gutter));
    }
  }
}


.site-is-loading {
  display: none;

  .js & {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 5;

    background-color: $white;
  }
}
