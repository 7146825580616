*,
*:before,
*:after{
  box-sizing: border-box;
}

html, body, div, span, applet, object, embed, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, xmp, pre,
a, abbr, abbr[title], acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, hr,
table, td, th,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, input, button, textarea, select, optgroup,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
  font: inherit;
  padding: 0;
  border: 0;
  margin: 0;
  outline: 0;
  background: transparent;
  vertical-align: baseline;
}


// HTML5
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}


// TYPE
ol,
ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  background-color: transparent;
  &:visited,
  &:active,
  &:hover {
    outline: 0;
  }
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: rem(-0.5px);
}

sub {
  bottom: rem(-0.25px);
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

b, strong {
  @include bold();
}

dfn, em {
  @include italic();
}

q,
blockquote {
  quotes:none;
  &:before,
  &:after {
    content: '';
    content: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include regular();
  @include font-family(true);
}

// IMAGES
img {
  display: block;
  border: 0;
  width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}


// IFRAME OBJECT EMBED
object,
embed,
iframe {
  width: 100%;
  height: 100%;
}


// FORM RESET
%form-reset {
  @include font-smoothing();
  @include appearance(none);
  box-shadow: none;
  border-radius: 0;
  border: none;
  outline: none;
  z-index: 1;
  position: relative;
  overflow: visible;

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }
}


// FORM STYLES
input {
  &[type=password],
  &[type=text],
  &[type=email],
  &[type=date],
  &[type=number],
  &[type=tel],
  &[type=password],
  &[type=search] {
    @extend %form-reset;
    display: block;
    width: 100%;
    color: $black;
    @include rem((font-size: 13px));
    @include rem((line-height: 18px));
    @include placeholder {
      @include rem((font-size: 13px));
      @include rem((line-height: 18px));
      color: $black;
    }
  }
  &[type=submit] {
    @extend %form-reset;
    cursor: pointer;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &[disabled],
  &[type="disabled"] {
    cursor: no-drop;
    @extend %opacity-3;
  }
}

textarea {
  @extend %form-reset;
  display: block;
  overflow: auto;
  resize: vertical;
  width: 100%;
  color: $black;
  @include rem((font-size: 13px));
  @include rem((line-height: 18px));
  @include placeholder {
    @include rem((font-size: 13px));
    @include rem((line-height: 18px));
    color: $black;
  }
}

select {
  width: 100%;
}

button {
  @extend %form-reset;
  &[type=submit] {
    cursor:pointer;
  }
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &[disabled],
  &[type="disabled"] {
    cursor: no-drop;
    @extend %opacity-3;
  }
}

[tabindex="-1"]:focus {
  outline: none !important;
}

optgroup {
  @include bold();
}


// CHROMEFRAME
.lt-ie9 {
  height: 100%;
  width: 100%;
  overflow: hidden;
  #chromeFrame {
    @extend %opacity-1;
    @extend %align-center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: $black;
    text-align: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    h1,h2 {
      color: $white;
      padding: rem(8px 0);
    }
    a {
      color: $brand;
      &:hover {
        text-decoration: underline;
      }
    }
    h1 {
      @include rem((font-size: 32px));
      @include line-height(36px);
    }
    h2 {
      @include rem((font-size: 24px));
      @include line-height(28px);
    }
  }
}
