// CHEVRON GENERATOR
@mixin chevron($cDirection: 'right', $cSize: 18px, $cBorderWidth: 2px, $cColor: #000) {
  content: '';
  display: block;
  border-top: 1px solid $cColor;
  border-right: 1px solid $cColor;
  width: $cSize/1.1;
  height: $cSize/1.1;
  border-width: $cBorderWidth;
  @media screen and (min-width: $large-mq) {
    width: $cSize;
    height: $cSize;
  }
  @if $cDirection == 'up' {
    transform: rotate(-45deg);
  }
  @if $cDirection == 'right' {
    transform: rotate(45deg);
  }
  @if $cDirection == 'down' {
    transform: rotate(135deg);
  }
  @if $cDirection == 'left' {
    transform: rotate(225deg);
  }
}
